import React from 'react';
import Signature from '../components/Signature';

export default function Showcase(props) {

    return (
        <>
            <Signature {...{
                name: 'Oliver Heward',
                email: 'oliver@fluidesign.co.uk',
                title: 'Developer',
                mcvAward: true,
                agencyAward: true,
                mobile: '0777369762',
                officePhone: '+44 (0)121 212 0121',
                discord: 'oliver#1234',
                termsSwitch: 'Fluid Digital'
            }} />
        </>
    )
}